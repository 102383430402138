import React, { useState } from "react"
import PropTypes from "prop-types"
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from "reactstrap"
import logo from "../images/dietlabs_logo.svg"

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [active, setActive] = useState('home')

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const handleClick = activeMenuItem => {
        setIsOpen(false)
        setActive(activeMenuItem)
    }

    return(
        <Navbar
            expand="md"
            container="xl"
            light
            fixed="top"
        >
            <NavbarBrand href="/">
                <img className="logo" src={logo} alt="Dietlabs" />
            </NavbarBrand>

            <NavbarToggler onClick={toggle} />
            <Collapse navbar isOpen={isOpen}>
                <Nav
                    className="ms-auto"
                    navbar
                >
                    <NavItem>
                        <NavLink href="#home" className={active==='home' ? 'active' : ''} onClick={() => handleClick('home')}>
                            Home
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#team" className={active==='team' ? 'active' : ''} onClick={() => handleClick('team')}>
                            Zespół
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#fund" className={active==='fund' ? 'active' : ''} onClick={() => handleClick('fund')}>
                            Fundusze norweskie
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#contact" className={active==='contact' ? 'active' : ''} onClick={() => handleClick('contact')}>
                            Kontakt
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
