import * as React from "react"
import { Container, Row, Col } from "reactstrap"
import logo from "../images/dietlabs_logo.svg"

const Footer = () => (
    <footer>
        <Container>
            <Row className="align-items-center">
                <Col md="4">
                    <img src={logo} className="logo" alt="Dietlabs" />
                </Col>
                <div className="d-md-none"><hr /></div>
                <Col md="8" className="text-md-end">
                    © 2022 DietLabs MedTech sp. z o.o., All Rights Reserved
                </Col>
            </Row>
        </Container>
    </footer>
)

export default Footer
